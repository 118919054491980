import C from '../../constants'

export const linkData = {
  info: [
    {
      href: '/blog/',
      text: 'Footer:info:articles',
    },
    {
      href: '/features/',
      text: 'Footer:info:features',
    },
    {
      href: '/pricing/',
      text: 'Footer:info:pricing',
    },
    {
      href: '/about/',
      text: 'Footer:info:about',
    },
  ],
  helpful: [
    {
      href: C.links.loginLink,
      text: 'Global:signIn',
      isInternal: false,
    },
    {
      href: C.links.signupLink,
      text: 'Global:signUp',
      isInternal: false,
    },
    {
      href: '/getting-started/',
      text: 'Footer:helpful:gettingStarted',
    },
    // {
    //   href: '/help-and-support/',
    //   text: 'Footer:helpful:help',
    // },
    // {
    //   href: '/contact-us/',
    //   text: 'Footer:helpful:contact',
    // },
  ],
  social: [
    {
      href: C.links.facebook,
      text: 'Facebook',
      isInternal: false,
    },
    {
      href: C.links.messenger,
      text: 'Messenger',
      isInternal: false,
    },
    {
      href: C.links.instagram,
      text: 'Instagram',
      isInternal: false,
    },
    {
      href: C.links.twitter,
      text: 'Twitter',
      isInternal: false,
    },
    {
      href: C.links.linkedIn,
      text: 'LinkedIn',
      isInternal: false,
    },
  ],
}
