import styled from 'styled-components'

const Navigation = styled.nav`
  display: flex;
  height: 60px;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 2;
  align-self: center;

  @media (max-width: 768px) {
    position: sticky;
    height: 8vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`
export default Navigation
