import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ imageName, alt }) => (
  <StaticQuery
    query={graphql`
      query AllImages {
        allFile(filter: { relativeDirectory: { eq: "images" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageEdge = data.allFile.edges.find(
        (edge) => edge.node.name === imageName,
      )
      if (!imageEdge) return null
      return (
        <Img
          fluid={imageEdge.node.childImageSharp.fluid}
          alt={alt}
          style={{ width: '100%' }}
        />
      )
    }}
  />
)

export default Image
