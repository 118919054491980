/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { jsx } from 'theme-ui'

import Button from '../Button'
import { useTranslation } from 'react-i18next'
import C from '../../constants'

const NavbarLinks = () => {
  const { t } = useTranslation()

  const handleOnSignInClick = () => {
    window.open(C.links.loginLink)
  }

  const handleOnSignUpClick = () => {
    window.open(C.links.signupLink)
  }
  return (
    <>
      <Button
        sx={{ margin: 2 }}
        variant="primary"
        onClick={handleOnSignInClick}
      >
        {t('Global:signIn')}
      </Button>
      <Button variant="outlined" onClick={handleOnSignUpClick}>
        {t('Global:signUp')}
      </Button>
    </>
  )
}

export default NavbarLinks
