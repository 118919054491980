/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Text, Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import Link from '../Link'
import { linkData } from './linkData'

const footerContainerProps = {
  flex: '1 1 auto',
  flexDirection: 'column',
  minWidth: '250px',
  alignItems: 'center',
}

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Flex bg="zircon" sx={{ flexWrap: 'wrap' }}>
      <Flex p={4} sx={footerContainerProps}>
        <Text pb={1} variant="linkHeader">
          {t('Footer:info:heading')}
        </Text>
        {linkData.info.map((item) => (
          <Box p="2px" key={item.href}>
            <Link
              key={item.href}
              href={item.href}
              text={item.text}
              isFooter={true}
            />
          </Box>
        ))}
      </Flex>
      <Flex p={4} sx={footerContainerProps}>
        <Text pb={1} variant="linkHeader">
          {t('Footer:helpful:heading')}
        </Text>
        {linkData.helpful.map((item) => (
          <Box p="2px" key={item.href}>
            <Link
              key={item.href}
              href={item.href}
              text={item.text}
              isFooter={true}
            />
          </Box>
        ))}
      </Flex>
      <Flex p={4} sx={footerContainerProps}>
        <Text pb={1} variant="linkHeader">
          {t('Footer:social:heading')}
        </Text>
        {linkData.social.map((item) => (
          <Box p="2px" key={item.href}>
            <Link
              key={item.href}
              href={item.href}
              text={item.text}
              isInternal={item.isInternal}
              isFooter={true}
            />
          </Box>
        ))}
      </Flex>
      <Flex p={4} sx={footerContainerProps}>
        <Text variant="detail">{t('Footer:business:copyright')}</Text>
        <Text variant="detail">{t('Footer:business:company')}</Text>
        <Text variant="detail">{t('Footer:business:companyId')}</Text>
        <Text variant="detail">{t('Footer:business:rights')}</Text>
      </Flex>
    </Flex>
  )
}

export default Footer
