/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'

const Button = ({ variant = 'primary', ...props }) => (
  <Box
    {...props}
    sx={{
      display: 'inline-block',
      textAlign: 'center',
      fontSize: 3,
      m: 0,
      px: 4,
      py: 2,
      borderRadius: 4,
      variant: `buttons.${variant}`,
      cursor: 'pointer',
      boxSizing: 'border-box',
    }}
  />
)

export default Button
