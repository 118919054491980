import styled from 'styled-components'

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10px;

  @media (max-width: 768px) {
    display: flex;
  }
`
export default Toggle
