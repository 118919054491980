/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import { Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'

import Navigation from './Navigation'
import Navbox from './Navbox'
import Toggle from './Toggle'
import Hamburger from './Hamburger'
import NavbarLinks from './NavbarLinks'
import Image from '../Image'

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation sx={{ backgroundColor: 'zircon' }}>
      <Box
        sx={{ width: ['150px', '200px', '220px', '220px'], cursor: 'pointer' }}
      >
        <Link to="/">
          <Image imageName="bmm_logo" alt="Company logo of Bookmemate" />
        </Link>
      </Box>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar
